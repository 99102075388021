/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header'
import './layout.css'
import Typography from '@material-ui/core/Typography'

const Layout = ({ drawerOpen, navPages, children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <div style={{ minHeight: '100vh', backgroundColor: '#FFF' }}>
          <Header
            siteTitle={data.site.siteMetadata.title}
            navPages={navPages}
            drawerOpen={drawerOpen}
          />
          <div
            style={{
              margin: `0 auto`,
              maxWidth: 960,
              padding: `0px 1.0875rem 1.45rem`,
              paddingTop: 100,
            }}
          >
            <main>{children}</main>
            <footer style={{ paddingTop: 10 }}>
              <Typography variant="caption">
                This work is licensed under a Creative Commons
                Attribution-NonCommercial-NoDerivs 3.0 New Zealand License.
              </Typography>
              <br></br>
              <a
                rel="license"
                class="urllink external"
                href="https://creativecommons.org/licenses/by-nc-nd/3.0/nz/"
                target="_blank"
              >
                <img
                  src="https://i.creativecommons.org/l/by-nc-nd/3.0/nz/80x15.png"
                  alt="Creative Commons License"
                  title="Creative Commons License"
                />
              </a>
            </footer>
          </div>
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
